<script>
export default {
  name: 'nav',
  computed: { 
    sidebarItems () {
      return [{
        _name: 'CSidebarNav',
        _attrs: { style: 'color: #000;' },
        _children: [
          {
            _name: 'CSidebarNavItem',
            name: 'Inicio',
            to: '/home',
            icon: 'cil-home'
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ['Catálogos']
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Clientes',
            to: '/customers',
            icon: 'cil-group',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Productos',
            to: '/products',
            icon: 'cil-truck',
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ["CFDI's"]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Facturas',
            to: '/invoices',
            icon: 'cil-copy',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Notas de Crédito',
            to: '/credit-notes',
            icon: 'cil-file',
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Recepción de Pagos',
            to: '/payments',
            icon: 'cil-money',
          },
          {
            _name: 'CSidebarNavTitle',
            _children: ["CFDI's RECIBIDOS"]
          },
          {
            _name: 'CSidebarNavItem',
            name: 'Recibidos',
            to: '/cfdis-import',
            icon: 'cil-copy',
          },          
        ]
      }]
    }
  }
}
</script>
